<template>
    <div class="flex h-full">
        <div class="loginImage-cnt w-1/2">
            <img :src="LoginImg" class="w-full">
        </div>
        <div class="w-1/2 form-cnt h-full">
            <form @submit.prevent="submitForm" class="pr-10">
                <div class="flex flex-col gap-8 pr-20">
                    <div>
                        <!-- <Logo1/> -->
                        <img :src="Logo1" class="logo">
                    </div>
                    <div>
                        <p class="block mb-2 text-black text-2xl font-semibold">Login to your account</p>
                    </div>
                    <div>
                        <div class="mb-8">
                            <label for="Email" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">Email</label>
                            <div class="flex items-center relative">
                                <input type="text" id="Email" v-model="formData.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" :class=" ( showError && !isEmailValid )  ? 'border-red-600' : 'border-gray-300'" placeholder="Enter Email" :disabled="isEmailFilled" @change="disableError" >
                            </div>
                            <span v-if="showError && !isEmailValid" class="text-red-600 text-xs">Invalid Email</span>
                        </div>
                        <div class="my-8">
                            <label for="password" class="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Password</label>
                            <div class="flex items-center relative">
                                <input type="password" ref="passRef" id="password" v-model="formData.password" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" placeholder="Enter Password" @blur="handleInputBlur">
                                <EyeClosed class="absolute right-4 cursor-pointer"  @click="togglePass" v-if="isPassVisible"/>
                                <Eye class="absolute right-4 cursor-pointer " @click="togglePass" v-else/>
                            </div>
                            <span v-if="showError && !isPassValid && formData.password" class="text-red-600 text-xs">Length should be greater than 11</span>
                            <div class="text-right" v-if="!isEmailFilled">
                                <router-link to="/forgot-password"  class="text-primary text-right text-xs mt-2 cursor-pointer">Forgot Password?</router-link>
                            </div>
                            <!-- <span v-if="$v.formData.password.$dirty && !$v.formData.password.required">Name is required.</span> -->
                        </div>
                       
                    </div>
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer" :class="{'btnDisable':!isFormFilled || disabled}" type="submit" :disabled="!isFormFilled || disabled">
                        <loader class="mr-5" v-if="disabled"/>
                           {{ !disabled ? 'Continue' : '' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    // import  LoginImg from "@/assets/svg/loginScreen.svg";
    import  Eye from "@/assets/svg/eye.svg";
    import  EyeClosed from "@/assets/svg/closeeye.svg";
    import { LoginImg, Logo1 } from "@/plugins/common.js";
    import { sendOtpForLogin } from "./services";
    import Loader from "@shared/loader";

    export default {
    components: {
        Eye,
        EyeClosed,
        Loader
    },
    data(){
        return {
            LoginImg,
            Logo1,
            disabled: false,
            requestId:null,
            formData: {
                email: '',
                password: ''
            },
            showError: false,
            isEmailFilled: false,
            isPassVisible: false
        }
    },
    mounted(){
        if(this.$route?.params?.email){
            this.formData.email = this.$route?.params?.email;
            this.isEmailFilled = true;
        }
    },
    methods: {
        async submitForm() {
            try {
                this.disabled = true
                this.showError = true
                if(!this.isEmailValid || !this.isPassValid) return
                const data = await sendOtpForLogin(this.formData)
                if (data.status == true) {
                    this.requestId = data.data.request_id
                    if(data.status_code=="1007") {
                        this.disabled = false
                        this.$router.push(`/update-password?request_id=${this.requestId}`)
                    }
                    else {  
                        this.disabled = false
                        this.$toast.success(data.message || 'OTP send successfully!')
                        this.moveToPath()
                    }
                }
                else {
                    this.disabled = false
                    this.$toast.error(data.message || 'Something went wrong!')
                }
            } catch (error) {
                this.disabled = false
                this.$toast.error('Something went wrong!')
            }
        },
        disableError(){
            this.disabled = false
        },
        togglePass(){
                this.$refs.passRef.type = this.$refs.passRef.type==='text' ? 'password' : 'text';
                this.isPassVisible = !this.isPassVisible;
        },
        handleInputBlur(){
            this.$refs.passRef.type = 'password';
        },
        moveToPath(){
            this.$router.push(`/otp-login?requestId=${this.requestId}`)
        },
        isValidEmail(email){
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailRegex.test(email);
        }
  },
  computed: {
    isFormFilled(){
        return this.formData?.email && this.formData?.password
    },
    isEmailValid(){
        return this.isValidEmail(this.formData.email)
    },
    isPassValid(){
        return this.formData?.password.length > 11 
    }
  }
}
</script>
<style scoped>
    div{
        /* border: 1px solid red;   */
    }

    .loginImage-cnt {
        width: 50%; 
        height: 100vh; 
        overflow: hidden; 
        display: flex;
    }
    .form-cnt{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
    }
    .logo{
        width: 215px;
        height: 72px;
    }
    .btnDisable{
        background-color: #DDD;
        color: #858585;
    }
    .rounded{
        border-radius: 10px;
    }
    input{
        font-size: 16px;
    }
</style>